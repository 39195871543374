export const firebaseAdminSdk = {
  "parm-names-not-numbers": {
    "project_id": "parm-names-not-numbers",
    "type": "service_account",
    "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
    "client_email": "firebase-adminsdk-lw7hu@parm-names-not-numbers.iam.gserviceaccount.com",
    "client_id": "105291671872495613012",
    "private_key_id": "b3cf77ee088bc041f24a82be3e3e465708306c87",
    "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDCJeqKvkarihyN\n13fbFNRerQlCDW1ve1pXDqAfW9aalsIn/npfL53nXezHOn284eMsv7SW8LcUJqjQ\nTQTMOrAOSw70LS5Gfoy5cOXOEwsdIFap+ei/zoxLY3Q5Rtb4ZN02tRSFhL7emtdN\nECEjoRoqHuh12l7s/Ph75iuTkUh7fM6TgWokVbRS/G0B3NhGicrbMFur52u0gB2L\nL2gyiJQxjx09p5MgR+hxe6vwWdErF0ur0qImaWpIv8Ms7Le1dZlY78uIeUJBgB4u\nEsg36rcg0C5sa6lJQOAtBX1JEE2k4rF4CXdJ8grykHrvkxjnFbGDNdsYzKyT6x7+\nFr10GLfnAgMBAAECggEAAfx+Uk4RDgc7NI5MSR8E7zUI/7aAdKAH9BUPuVJotyss\nsFkPqnKjxG63EfC8wZZ0CwypDdRHozqkBoGLN4WwMG0/KWte6lS4HtuTVlDmj7U5\nfcKzSWcVE15gnYdmgqMRilI+9ADALSnbYwvzstgemui3CyGhT/5kkmvv7TIS0kSX\njA83gHBNH8+8gzHIwHpZp41LxvUUO4ocpdsJCyFbRwuBboHdrnVRP7DsJmJEMBMh\nSb7qlLuuIC4NmNOVSKbEGCxyayAbOwlh6I81elpHkSSwgB0ZcgoEpOgjIzqJTPr/\n6mrS2hjtem1ZGlhJDq/v3rToUs92NK+GxZShTwdBLQKBgQDuQ/3lQQXCNwHoOmPp\nISTU9CEXBLyU4ThQ8dX1jNcK5R0Ib0w0gpMlYPylBKQv1Bnag5v3y80g5T0077JC\no1ep1b4wDyxqSQH5rPEdGWOuXT/ynWHO9j7HccJksjemu8s2Zey+qUG5k40+j6ye\nxy5yb7q83dyF4GYj6J1wBYw8swKBgQDQmUrMOd3DGrILAonx6Nbg8NCGzKObma/1\n2Ki1+PcPWYKB/04gETPS5/WjV/up/befyOAP216t21QPhqgvHGgaTDK3syJTz8mR\n6eRf4VcmAF0EVNKo0+GSvynhR11RYT7YpQP99JQ58hLg29arY0tB4oEGSMPbDDNK\ndVggsz/Z/QKBgQDZPMK8CHngptilTL+VajOzudaFrzrlnycCnkxO8peKhnTfIOLh\nKYBR30jaiA3O0dq0obRNzNJ9QKV6o2TrXxRpc8lQ9jSvv1r+stCvkFuTOdA5O4dW\n9Ksumyj7OIaHDcftFCczewzCz6ZDOSnRJpfRnM8srjgHFB7sdIU/Ar/nAwKBgCtV\nDLKr+qNC8mVTG7DC+5Aytj6qV/pEzMa3QSS8GSZ5I6E5ldMXf7p6MQ7DgiXn7LMm\nmVTx7xMSkr2+7UUME5wS5B5A+gqLCYZkHerE9sOQI8J4lGI50YwYZezEVGBJYTLD\n5LZmjjwTS1Smlkt4DvQax0604uqn+CephKsWUVdJAoGBAKLwX83aRFFMi0yVPGg7\nRxudVBVN0PjKlXrFGzryamB9IyCGvPInqJijmgbIy5bQbsa/0k1cNjpo3MuN312H\nV2MYhRDo3mU4S+T4qkTGFhDWFuJZeGip7PtsLl0aiquiysm7nDNckjTf7e9mnGU7\nRbTdJt4svmtzHLqUjEjJKdfF\n-----END PRIVATE KEY-----\n",
    "token_uri": "https://oauth2.googleapis.com/token",
    "auth_uri": "https://accounts.google.com/o/oauth2/auth",
    "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-lw7hu%40parm-names-not-numbers.iam.gserviceaccount.com"
  },
  "com-f5-parm": {
    "auth_uri": "https://accounts.google.com/o/oauth2/auth",
    "private_key_id": "36a1aacde2f910ad3557b0e80cce3fb83d7f6868",
    "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
    "type": "service_account",
    "project_id": "com-f5-parm",
    "client_id": "110903525094118609852",
    "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDxw5p8/aE80swY\nvqWkq68z6jnEnYX0DIXgAVrm+Q7/rZA4CNoKNt9sNV/zfeeX5fKKNH79afgeYH1X\nPM7tkaJD55nFo7KVZWt5GIlPwA30ZGwwqSYxkduWVhU7og30MRUWHRcjxUR6rNax\nR7mzr3nb16T3VjBwL7Ere003Pd/ZAWvQ6fp9XzbDZuSysZAVEeqWRcXDFevFZVrt\ndECM3vohj46bhoVhyGKC86CSkTgxr1+stMs4nrotWy3ED7wx8LWynhGx2DJd9bIz\n2I/lNbhRwk67sHmzabRaaindFDKdHt0JaBktSq/TvGUAdGniqUMqAVZAErrK1nNT\n0R6XPRRzAgMBAAECggEAD3P+QvD/RmD+9LHy6oSizUGycZPUBRZr1Dah9dODluok\nJsDCFxcMzuDWgjzQ+c+T4iua1l3YozBtso7Ldznw0kBSfuSIvK3f4bykwjk3ayCk\nHbQiIdb93y0fbFFebPZaL7CqUvn/94tmqaw4/XhA5MFy5PBYZkNWIr5d4CMPVNJ3\noPiX88uArOxM7fMxwesmnoC8HhZEGKKwU0yp2fV0zP/6wfItPzdXeO0rYTGPz4Pw\nOD9pzw3psjna1HQczsdKXu86WERyP8/Fw3QKg/SzXPW+WnvyWaMn1SSBGkmsuWsp\nncI56ECRXlWMZhfvPJmJ80NSnZmNnnhS0U2mxwlfGQKBgQD5xc0SbFDSf2arTBjp\n4UoyXgoD9jc8nHkffbWvn9cV8g0K2qIv5KNA38VU9QrAsGZkPM4mq2FRoVsWmEtF\nR3ez85uThPailS+8SMgX6S8CxnbPR2lR87l90yu4Ri1tpCJZaKEHR5/Ux1Kbz0R0\nrMD3yzPR7rn3Sc8D5r6J9K11FQKBgQD3yq/TU2WCXjg2VHA17ouc/BPAZ9PbKrVL\nYzePPOLPkBS13sepEQ/xxR7BDR7CPaYiO22DtKyl9NXrZUcbYEqdBOLCKcbyOHxI\n9MsL/72pCWypq0LD6p6VepKz6L6eKRa6sfW4F/65oD/RZzC7bTpVtd8QRR0qanto\nufwGcgdVZwKBgQCSzHSI3WxrK0G2b9U8sJKhI7ATQASa9QQjRAkinXkWhtqvhGxK\nZcClgl9CXXriYUrsouguxg8+oO0eTIgAwc5Sz1yiyUUDrVcO/F/N3dkpcmelz7zQ\n08QzdYd+mJBenlcp4VqzIp+HuVaOCLWdNWN8v8tHGV3nP1QSYO2fu1SvOQKBgQCo\nv1CkEtxDPxT68uAD6nMLsqNk2ZBuZhRWxbe1hVpUziM8g/3mxibcmEhMYjn7P+6e\ny2fWVLlqv2TbTPpyy02kxwoB/l9s30jMimCoyD4KGs/0cBwkvefHllTHzo3ZudOv\nyrey6ixfj6FGYwbN7SHX8uZ49whYtXFbraTTYt5ZqwKBgQDwNqG+7W1w9cUvwDvi\nQBPCKuLmqqGpOakWzE233c2wUBV5pgsX2jR1ZcUIpCI6F8G4NrbGDhc5AmB2N4K4\nZTdCFBEhpt76kYh/qA7QZOtpCDYwhidbymvCFdn6hYm+Tv87Go+0vvYRWgNJGJOY\nDV7L/B6NIeQH/JjxBmMtprHFxA==\n-----END PRIVATE KEY-----\n",
    "client_email": "firebase-adminsdk-s1kin@com-f5-parm.iam.gserviceaccount.com",
    "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-s1kin%40com-f5-parm.iam.gserviceaccount.com",
    "token_uri": "https://oauth2.googleapis.com/token"
  }
};