// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import { Environment } from './environment.interface';

export const environment: Environment = {
  "numResponses": 17,
  "header": "Oh fuck! _Oh fuck!_",
  "maxResponses": 3,
  "host": "f5-0000",
  "app": "f5",
  "title": "F5",
  "metaDescription": "Oh fuck! Ohhhhhh FFFFFFUCK. You just spilled a fuckton of hot fucking water on your lap! What now?",
  "stage": "prod",
  "metaTitle": "FUCKFUCKFUCKFUCKFUCK"
};