export const firebase = {
  "default": {
    "messagingSenderId": "171315357154",
    "appId": "1:171315357154:web:2d133f7a25d9ae1ffa05bb",
    "projectId": "parm-names-not-numbers",
    "storageBucket": "parm-names-not-numbers.appspot.com",
    "databaseURL": "https://parm-names-not-numbers.firebaseio.com",
    "measurementId": "G-82KFXEBDJV",
    "authDomain": "parm-names-not-numbers.firebaseapp.com",
    "apiKey": "AIzaSyBOYnOzGtGpgvUOl5TGr-BA1Ut1i5rtjLo"
  },
  "parm": {
    "measurementId": "G-K9H3K53LJ7",
    "appId": "1:868795766038:web:5f96263be208891726ac37",
    "databaseURL": "https://com-f5-parm.firebaseio.com",
    "apiKey": "AIzaSyAXnD8jB9Wizj8zADPdYjZ51FpyVD4JuGE",
    "projectId": "com-f5-parm",
    "storageBucket": "com-f5-parm.appspot.com",
    "messagingSenderId": "868795766038",
    "authDomain": "com-f5-parm.firebaseapp.com"
  }
};